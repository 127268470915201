import React, { FC } from 'react';
import styled from 'styled-components';
import { NameAnimation } from '../NameAnimation';
import { fadeInUp } from '../../styles/animations';
const plus = require('../../assets/PlusBlack.png');

type TeamFaceProps = {
	setOpen: (value: boolean) => void;
};

export const TeamFace: FC<TeamFaceProps> = ({ setOpen }) => {
	return (
		<Wrapper>
			<h1>
				Team <br /> <span>Work.</span>
			</h1>
			<section>
				<NameAnimation name="GOSHAWTY" />
				<NameAnimation name="VICIONE" isReverse />
				<NameAnimation name="VLADIKK317" />
				<NameAnimation name="NICOLAS CAGE" isReverse />
				<NameAnimation name="YANKIE" />
				<NameAnimation name="BU1BAZAWR" isReverse />
				<NameAnimation name="DXDY0X" />
				<NameAnimation name="SLEG.FX" isReverse />
			</section>
			<div onClick={() => setOpen(true)}>
				<img src={plus} alt="plus" />
			</div>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	height: 820px;
	width: 565px;
	cursor: pointer;

	&:hover > div img {
		opacity: 0.5;
	}

	@media screen and (max-width: 1170px) {
		height: 600px;
		width: 400px;
	}

	> div {
		position: absolute;
		bottom: 30px;
		right: 30px;
		width: 40px;

		img {
			cursor: pointer;
			width: 100%;
		}
	}

	// custom styles
	section {
		margin-top: 110px;
		transform: rotate(-5deg);
		width: 110%;
		margin-left: -20px;

		@media screen and (max-width: 1170px) {
			margin-top: 50px;
		}
	}

	h1 {
		font-family: 'SF-Bold';
		padding-left: 95px;
		padding-top: 100px;
		text-align: left;
		line-height: 0.9;
		font-size: 120px;
		animation: ${fadeInUp} 5s forwards;

		span {
			font-size: 150px;
		}

		@media screen and (max-width: 1170px) {
			padding-left: 60px;
			padding-top: 60px;
			font-size: 90px;

			span {
				font-size: 110px;
			}
		}

		background: -webkit-gradient(
			linear,
			left top,
			right top,
			from(#90ca5a),
			color-stop(50%, #d7d65a),
			color-stop(70%, #dac355)
		);
		background: -webkit-linear-gradient(
			left,
			#90ca5a,
			#d7d65a 50%,
			#dac355 70%
		);
		background: linear-gradient(90deg, #90ca5a, #d7d65a 50%, #dac355 70%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
`;
