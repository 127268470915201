import React, { FC } from 'react';
import styled from 'styled-components';
import {
	TagCEO,
	TagCMO,
	TagCOO,
	TagCrypto,
	TagCryptoActivities,
	TagFX,
	TagIndices,
	TagMP,
	TagNews,
	TagPA,
	TagTrader,
	TagXAU,
} from '../Tags';
const plus = require('../../assets/PlusBlack.png');
const goshawty = require('../../assets/goshawty.webp');
const vicione = require('../../assets/vicione.webp');
const vladikk317 = require('../../assets/vladikk317.webp');
const cage = require('../../assets/cage.webp');
const bu1bazawr = require('../../assets/bu1bazawr.webp');
const yankie = require('../../assets/yankie.webp');
const dxdy0x = require('../../assets/dxdy0x.webp');
const misterx = require('../../assets/misterx.webp');

type CreatedByBackProps = { setOpen: (value: boolean) => void };

export const CreatedByBack: FC<CreatedByBackProps> = ({ setOpen }) => {
	return (
		<Back>
			<PersonWrap>
				<PersonBlock>
					<img src={goshawty} alt="goshawty" />
					<h2>GoShawty</h2>
					<TagWrap>
						<TagCEO />
						<TagTrader />
						<TagPA />
						<TagXAU />
						<TagIndices />
					</TagWrap>
				</PersonBlock>
				<PersonBlock>
					<img src={vicione} alt="vicione" />
					<h2>Vicione</h2>
					<TagWrap>
						<TagTrader />
						<TagMP />
						<TagFX />
						<TagIndices />
					</TagWrap>
				</PersonBlock>
				<PersonBlock>
					<img src={vladikk317} alt="vladikk317" />
					<h2>Vladikk317</h2>
					<TagWrap>
						<TagTrader />
						<TagPA />
						<TagNews />
						<TagFX />
						<TagXAU />
					</TagWrap>
				</PersonBlock>
				<PersonBlock>
					<img src={dxdy0x} alt="dxdy0x" />
					<h2>DXDY0X</h2>
					<TagWrap>
						<TagCrypto />
						<TagCOO />
					</TagWrap>
				</PersonBlock>
			</PersonWrap>
			<PersonWrap>
				<PersonBlock>
					<img src={bu1bazawr} alt="bu1bazawr" />
					<h2>bu1bazawr</h2>
					<TagWrap>
						<TagTrader />
						<TagPA />
						<TagFX />
					</TagWrap>
				</PersonBlock>
				<PersonBlock>
					<img src={yankie} alt="yankie" />
					<h2>Yankie</h2>
					<TagWrap>
						<TagCMO />
						<TagFX />
					</TagWrap>
				</PersonBlock>
				<PersonBlock>
					<img src={cage} alt="cage" />
					<h2>Nicolas Cage</h2>
					<TagWrap>
						<TagTrader />
						<TagPA />
						<TagFX />
						<TagXAU />
					</TagWrap>
				</PersonBlock>
				<PersonBlock>
					<img src={misterx} alt="misterx" />
					<h2>Sleg.fx</h2>
					<TagWrap>
						<TagTrader />
						<TagPA />
						<TagFX />
						<TagIndices />
						<TagCryptoActivities />
					</TagWrap>
				</PersonBlock>
			</PersonWrap>
			<HeadingBlock>
				<h1>Meet Our People.</h1>
				<div>
					<div onClick={() => setOpen(false)}>
						<img src={plus} alt="plus" />
					</div>
				</div>
			</HeadingBlock>
		</Back>
	);
};

const Back = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	width: 100%;
`;

const HeadingBlock = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;

	h1 {
		font-size: 97px;
		width: 1140px;
		margin-bottom: 40px;
		margin-left: 40px;
	}

	div {
		width: 40px;

		img {
			transform: rotate(45deg);
			width: 100%;
			margin-top: 64px;
			margin-left: -120px;

			&:hover {
				opacity: 0.5;
				cursor: pointer;
			}
		}
	}

	@media screen and (max-width: 1170px) {
		h1 {
			width: 810px;
			font-size: 77px;
		}

		div img {
			margin-top: 52px;
		}
	}
`;

const PersonWrap = styled.div`
	display: flex;
	margin-left: 30px;
	margin-top: 50px;

	&:nth-of-type(2) {
		margin-left: 100px;
		margin-top: 0px;
	}
`;

const PersonBlock = styled.div`
	position: relative;
	width: 200px;
	margin-left: 20px;
	z-index: 7;

	img {
		transition: 0.5s;
		width: 100%;
	}

	h2 {
		font-family: 'SF-Bold';
		transition: 0.5s;
		text-align: center;
	}

	> div {
		opacity: 0;
	}

	&:hover img,
	&:hover h2 {
		opacity: 0;
	}

	&:hover > div {
		opacity: 1;
	}

	@media screen and (max-width: 1170px) {
		width: 130px;
	}
`;

const TagWrap = styled.div`
	position: absolute;
	z-index: 5;
	width: 200px;
	height: 220px;
	margin-top: -220px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 30px;
	transition: 0.5s;

	@media screen and (max-width: 1170px) {
		width: 130px;
		height: 159px;
		margin-top: -155px;
	}
`;
